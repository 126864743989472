<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">{{ $t("template.templateTableTitle") }}</h4>
        </md-card-header>
        <md-card-content>
          <template-crud :table-header-color="getTheme"></template-crud>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import TemplateCrud from "@/components/Templates/TemplatesCrud.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TemplateCrud,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>