<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['name']"
      @rowClick="rowClick"
    />
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
export default {
  name: "templates-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      data: {},
      loading: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Tipo de dispositivo", value: "deviceType" },
        { text: "Tecnología", value: "technologyType" },
        { text: "Timestamp", value: "timestamp" },
      ],
    };
  },
  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("template/getTemplates", options)
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "template",
        params: { template: event.id, tab: "details" },
      });
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>